import styled from 'styled-components'

import { Accordion as MuiAccordion } from '@material-ui/core'
import { AccordionSummary as MuiAccordionSummary } from '@material-ui/core'
import { AccordionDetails as MuiAccordionDetails } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { Badge as MuiBadge } from '@material-ui/core'

export const Accordion = styled(styled(MuiAccordion)(spacing))`
background-color:transparent;
box-shadow: none;
   border: none;
&::before {
   content: none;
 }
`

export const AccordionSummary = styled(MuiAccordionSummary)`
   background-color: transparent;
   &.hideExpandCollapse {
      display: none;
   }
`

export const AccordionDetails = styled(MuiAccordionDetails)`
background-color: transparent
`
