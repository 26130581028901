import { AddressSearchOption } from '@/app/ui-new/components/AddressSearchBar/AddressSearchBar'
import { ValidatedAddress } from './ValidatedAddress'

import {
    AddressType,
    EquipmentType,
    PaymentTypeOption,
    Provider,
    TariffType,
    TransportType,
} from '@lazr/openapi-client'

import {
    Address,
    Country,
    CoverageCommodityType,
    Lane,
    PackageType,
    Search,
    SearchRfq,
    SearchRfqAccessorial,
    SearchRfqHandlingUnit,
    SearchRfqCustomsDetail,
    ResultDocument,
    SearchRfqQuote,
    Coverage,
} from '@/app/model'
import { LabelSizeType } from '@lazr/enums'

export type InvalidationDialog = {
    isInvalidationDialogOpen?: boolean
    invalidationFunction?: (...args: any[]) => void
    invalidationArguments?: any
}

export interface MarketPlace {
    fillMarketPlaceTrigger: boolean | null
    order: Partial<Search>
    handlingUnitAccessorialList: SearchRfqAccessorial[]
    serviceAccessorialList: SearchRfqAccessorial[]
    originAccessorialList: SearchRfqAccessorial[]
    destinationAccessorialList: SearchRfqAccessorial[]
    packageTypeList: PackageType[]
    countryList: Country[]
    coverageCommodityTypeList: CoverageCommodityType[]
    originFullAddress: AddressSearchOption | null
    destinationFullAddress: AddressSearchOption | null
    dutyBillFullAddress: AddressSearchOption | null
    customsBrokerFullAddress: AddressSearchOption | null
    lastCompletedStep: MarketPlaceStep
    currentStep: MarketPlaceStep
    isCoverageExclusionsAccepted: boolean | undefined
    isQuoteFetchingComplete: boolean
    quoteFetchingResultsCompleted: Record<string, boolean>
    quoteFetchingSearchProgress: number
    marketPlaceErrors: MarketPlaceErrors
    invalidationDialog: InvalidationDialog
    promptModalLeave: boolean
    originAddressBookAddress: Address | null
    destinationAddressBookAddress: Address | null
    originSaveToAddressBook: boolean
    destinationSaveToAddressBook: boolean
    selectedValidatedOriginAddress: ValidatedAddress | null
    selectedValidatedDestinationAddress: ValidatedAddress | null
    openReasonsModal: boolean
    isPrepared: boolean
    openFedexGroundModal: boolean
    paymentType: PaymentTypeOption
    collect: Partial<Collect> | null
    userLanes: Lane[] | null
    selectedLane: Lane | null
    rescheduledPickupDate: string
    promptUnsavedChangesDialog: boolean
    marketplaceVersion: number
}

export interface MarketPlaceErrors {
    rfqIsDirty: Partial<Record<keyof SearchRfq, boolean>>
    cargoIsDirty: Partial<Record<keyof SearchRfqHandlingUnit, boolean>>[]
    rfqErrors: Partial<Record<keyof SearchRfq, string>>
    cargoErrors: Partial<Record<keyof SearchRfqHandlingUnit, string>>[]
    customsDetailErrors: Partial<Record<keyof SearchRfqCustomsDetail, string>>[]
    customsDetailIsDirty: Partial<Record<keyof SearchRfqCustomsDetail, boolean>>[]
    rfqCustomsErrors: Partial<Record<keyof SearchRfq, string>>
    rfqCustomsIsDirty: Partial<Record<keyof SearchRfq, boolean>>
    handlingUnitLinkErrors: string[]
    handlingUnitLinkIsDirty: boolean[]
    isSubmitted: boolean
}

export interface AddressInformation {
    state?: string | null
    country?: Country | null
    city?: string | null
    streetAddress?: string | null
    streetAddress2?: string | null
    streetAddress3?: string | null
    postalCode?: string | null
    addressType?: AddressType | null
    detectedAddressType?: AddressType | null
    accessorials?: SearchRfqAccessorial[]
    pickupDate?: string | null
    fullAddress: AddressSearchOption | null
    originAddressBookAddress: Address | null
    destinationAddressBookAddress: Address | null
}

export interface AddressInformationErrors {
    state?: string
    country?: string
    city?: string
    streetAddress?: string
    streetAddress2?: string
    postalCode?: string
    pickupDate?: string
}

export interface CompleteCutomsOrderStepErrors {
    billDutiesToParty?: string
    importExport?: string
    dutyBillToCity?: string
    dutyBillToContactEmail?: string
    dutyBillToCompanyName?: string
    dutyBillToContactName?: string
    dutyBillToContactPhone?: string
    dutyBillToCountry?: string
    dutyBillToPostalCode?: string
    dutyBillToState?: string
    dutyBillToStreetAddress?: string
    dutyBillToAccountNumber?: string
    reasonForExport?: string
    nonDeliveryHandling?: string
    otherReasonForExport?: string
    customsBroker?: string
    customsBrokerCity?: string
    customsBrokerEmail?: string
    customsBrokerContactName?: string
    customsBrokerContactPhone?: string
    customsBrokerContactPhoneExt?: string
    customsBrokerCountry?: string
    customsBrokerPostalCode?: string
    customsBrokerState?: string
    customsBrokerStreetAddress?: string
    customsBrokerStreetAddress2?: string
    customsBrokerAccountNumber?: string
    taxIdentificationNumber?: string
    incotermsType?: string
    commercialInvoiceUserProvided?: string
}

export interface CompleteOrderHazmatStepErrors {
    hazmatClass?: string
    hazmatMode?: string
}

export interface CompleteOrderStepErrors {
    companyName?: string
    contactName?: string
    contactPhone?: string
    contactEmails?: string
    openTime?: string
    closeTime?: string
}

export interface TransportInformation {
    rfq: Partial<SearchRfq> | undefined
}

export interface ProgressSummaryInformation {
    lastCompletedStep: MarketPlaceStep
    currentStep: MarketPlaceStep
    isQuoteFetchingComplete: boolean
    handlingUnitAccessorialList: SearchRfqAccessorial[]
    serviceAccessorialList: SearchRfqAccessorial[]
    originAccessorialList: SearchRfqAccessorial[]
    destinationAccessorialList: SearchRfqAccessorial[]
    packageTypeList: PackageType[]
    coverageCommodityTypeList: CoverageCommodityType[]
}

export enum MarketPlaceStep {
    START = 0,
    LOAD_AND_TERMS = 1,
    ORIGIN_AND_DESTINATION = 2,
    COVERAGE = 3,
    RESULTS = 4,
    ADDITIONAL_DETAILS = 5,
    CHECKOUT = 6,
    THANK_YOU = 7,
}

export enum MarketPlaceStepName {
    START = 'START',
    LOAD_AND_TERMS = 'LOAD_AND_TERMS',
    ORIGIN_AND_DESTINATION = 'ORIGIN_AND_DESTINATION',
    COVERAGE = 'COVERAGE',
    RESULTS = 'RESULTS',
    ADDITIONAL_DETAILS = 'ADDITIONAL_DETAILS',
    CHECKOUT = 'CHECKOUT',
    THANK_YOU = 'THANK_YOU',
}

export enum OriginAndDestinationStep {
    LANES = 1,
    ORIGIN = 2,
    DESTINATION = 3,
}

export interface AddressConfirmationInformation {
    streetAddress: string | null
    streetAddress2: string | null
    streetAddress3: string | null
    city: string | null
    state: string | null
    country: Country | null
    postalCode: string | null
    pickupDate: string | null
    companyName: string | null
    contactName: string | null
    contactEmails: string[] | null
    notify: boolean | null
    contactPhone: string | null
    contactPhoneExtension: string | null
    poNumber: string | null
    openTime: string | null
    closeTime: string | null
    accessorials: SearchRfqAccessorial[]
    instructions: string | null
    transitDaysMin: number
    transitDaysMax: number
    estimatedDeliveryDate: string | null
    estimatedTimeOfArrival: string | null
}

export interface CompleteCustomsOrderInformation {
    rfq: Partial<SearchRfq> | undefined
    dutyBillFullAddress: AddressSearchOption | null
    customsBrokerFullAddress: AddressSearchOption | null
    documents: ResultDocument[] | null
}

export interface CompleteOrderInformation {
    streetAddress: string | null
    streetAddress2: string | null
    streetAddress3: string | null
    city: string | null
    state: string | null
    country:  Country | null
    postalCode: string | null
    addressType?: AddressType | null
    detectedAddressType?: AddressType | null
    pickupDate: string | null
    accessorials: SearchRfqAccessorial[]
    contactName: string | null
    contactEmails: string[] | null
    contactPhone: string | null
    contactPhoneExtension: string | null
    companyName: string | null
    notify: boolean | null
    openTime: string | null
    closeTime: string | null
    instructions: string | null
    poNumber: string | null
    customQuoteRequested: boolean
    addressBookAddress: Address | null
    instructionsMaxCharacter: number | null
}

export interface TransportConfirmationInformation {
    isBookedWithCoverage: boolean | null
    commodityType: CoverageCommodityType | null
    shipmentCoverageValue: number | null
    customsBroker: string | null
    clientNumber: string
    threePlNumber: string
    carrierDisplayName: string
    transportType: TransportType
    equipmentType: EquipmentType | null
    provider: Provider
    serviceLevel: string
    transitDaysMax: number
    transitDaysMin: number
    carrierIdValue: string
    tariffType: TariffType
    isDocumentOnly: boolean | null
    useThermalPrinter: boolean | null
    labelSizeType: LabelSizeType | null
    handlingUnits: Partial<SearchRfqHandlingUnit>[] | null
}

export interface Collect {
    carrier: {
        id: string
        name: string
        transportType: TransportType
    }
    accountNumber: string
}

export type ResultsCompleted = Record<string, boolean>

export interface Result {
    quote: SearchRfqQuote
    coverage: Coverage | null
}
