import {
    Accessorial,
    type Organization,
    type SearchRfqAccessorial,
    type SearchRfqHandlingUnit,
    type SearchRfqHandlingUnitAccessorial,
} from '../../model'
import {
    DimensionUnit,
    EquipmentType,
    PackageType,
    TransportType,
    OrganizationType,
} from '@lazr/openapi-client'
import { convertDimensionToImperial, convertWeightToImperial } from '@lazr/utilities'
import { BLOCK_AND_BRACE_CODE } from '@/app/ui/lib/constants'
import { WeightUnit } from '@lazr/enums'
import { AccessorialCard } from '@/app/ui-new/pages/marketplace/helpers/marketplaceHelpers'


export const shouldForceCustomQuoteForEquipmentType = (equipmentTypeId?: EquipmentType | null): boolean =>
    equipmentTypeId === EquipmentType.FLATBED || equipmentTypeId === EquipmentType.FLATBED_ROLL_TITE || equipmentTypeId === EquipmentType.REEFER || equipmentTypeId === EquipmentType.SPECIALIZED

export const shouldForceCustomQuoteForEquipmentTypeException = (equipmentTypeId?: EquipmentType | null): boolean =>
    equipmentTypeId === EquipmentType.REEFER || equipmentTypeId === EquipmentType.SPECIALIZED

export const shouldForceCustomQuoteForTransportTypeException = (
    transportTypeId?: TransportType | null
): boolean => transportTypeId === TransportType.OTHER

export const shouldForceCustomQuoteForTransportType = (transportTypeId?: TransportType): boolean =>
    transportTypeId === TransportType.FTL || transportTypeId === TransportType.OTHER

export const shouldForceCustomQuoteForCargo = (cargo: {
                                                        quantity: number,
                                                        length: number
                                                        lengthUnit: DimensionUnit
                                                        width: number
                                                        widthUnit: DimensionUnit
                                                        height: number
                                                        heightUnit: DimensionUnit
                                                        weight: number
                                                        weightUnit: WeightUnit
                                                        packageType: PackageType }[],
                                                        organizationName?: string,
                                                        transportType?: TransportType,
): boolean =>
    cargo.some((handlingUnit) => {
        const length = convertDimensionToImperial(handlingUnit.length || 0, handlingUnit.lengthUnit || DimensionUnit.IN).dimension
        const width = convertDimensionToImperial(handlingUnit.width || 0, handlingUnit.widthUnit || DimensionUnit.IN).dimension
        const height = convertDimensionToImperial(handlingUnit.height || 0, handlingUnit.heightUnit || DimensionUnit.IN).dimension
        const weight = convertWeightToImperial(handlingUnit.weight || 0, handlingUnit.weightUnit || WeightUnit.LB).weight

        const numberOfPallets = cargo.reduce((totalQty, currentHandlingUnit) =>
            totalQty + (currentHandlingUnit.quantity ?? 0), 0)

        return width > 240
            || height > 102
            // || length > 144 This was moved to backend to only apply to RR
            || (width > 101 && length > 101)
            || weight > 80000
            || handlingUnit.packageType === PackageType.OTHER
            || (organizationName === 'Labelink' && numberOfPallets >= 15 && transportType === TransportType.LTL )
    })

export const shouldForceCustomQuoteForLiftgateMaxHeight = (handlingUnits: Partial<SearchRfqHandlingUnit>[], newAcc: AccessorialCard[]): boolean => {
    if (!newAcc || !handlingUnits) {
        return false
    }

    const hasMoreThanMaxHeight = handlingUnits.some((hu) => hu.height && hu.height > 96)
    const selectedOrNotRemovableAccessorials = newAcc.filter((a) => a.selected || !a.isRemovable)
    const hasLiftgate = selectedOrNotRemovableAccessorials
        .some((acc) => acc.accessorial.code === 'LGPU' || acc.accessorial.code === 'LGDEL')
    const hasForceCustomQuote = selectedOrNotRemovableAccessorials
        .some((acc) => acc.accessorial.forceCustomQuote === true)

    return (hasLiftgate && hasMoreThanMaxHeight) || hasForceCustomQuote
}

export const shouldForceCustomQuoteForAccessorials = (
    accessorials: SearchRfqAccessorial[],
    cargoAccessorials: SearchRfqHandlingUnitAccessorial[],
): boolean => cargoAccessorials
    .concat(accessorials)
    .some((rfqAccessorial) => rfqAccessorial.accessorial.forceCustomQuote)

export const shouldForceCustomQuoteForBlockAndBrace = (
    accessorials: SearchRfqAccessorial[],
    cargo: Partial<SearchRfqHandlingUnit>[],
): boolean => {
    const containsBlockAndBrace = accessorials
        .some((rfqAccessorial) => rfqAccessorial.accessorial.code === BLOCK_AND_BRACE_CODE)

    const containsForklift = cargo.some((handlingUnit) => handlingUnit.packageType === PackageType.FORKLIFT)

    return containsBlockAndBrace && !containsForklift
}

export const shouldForceCustomQuoteForHazmatLTL = (
    cargo: Partial<SearchRfqHandlingUnit>[],
    transportType?: TransportType,
): boolean => {
    console.debug('shouldForceCustomQuoteForHazmatLTL', cargo, transportType)
    return cargo.some(cargoItem => transportType === TransportType.LTL &&
        cargoItem.accessorials?.some(
            (rfqAccessorial) => rfqAccessorial.accessorial.code ===  'HAZM'
        )
    )
}

export const downloadFile = (data: string, fileName: string, mimeType = 'octet/stream'): void => {
    const a = document.createElement('a')

    const blob = new Blob([ data ], { type: mimeType })
    const url = window.URL.createObjectURL(blob)

    a.setAttribute('style', 'display: none')
    a.setAttribute('href', url)
    a.setAttribute('download', fileName)

    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
}


export const userIsSystemOrThreePlAgent = (userOrganization: Organization | null) => {
    if (!userOrganization) return false
    return userOrganization.type === OrganizationType.THREE_PL || userOrganization.type === OrganizationType.SYSTEM
}


export const isObjectEmpty = (obj: any) => {
    // Check if the object is truly empty
    if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
        for (const key in obj) {
            // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
            if (obj.hasOwnProperty(key)) {
                if (typeof obj[key] === 'object' && !isObjectEmpty(obj[key])) {
                    return false
                }if (typeof obj[key] !== 'object') {
                    return false
                }
            }
        }
        return true
    }
    return false
}

export const toArray = (input: any) => {
    if (Array.isArray(input)) {
        return input
    }
    if (input != null) {
        // Check for non-null and non-undefined
        return [input]
    }
    return []
}

/**
 * SERVER ERROR MESSAGE
 */
export interface ServerErrorMessage {
    url: string,
    status: number,
    statusText: string,
    body: {
        success: boolean,
        error: {
            message: {
                type: 'FORM' | 'GLOBAL',
                statusCode: number,
                field: string,
                message: string
            }[]
        }
    }
}

export const generateServerErrorMessage = (
    errorMessage: string,
    statusCode?: number,
    statusText?: string,
): Partial<ServerErrorMessage> => {
    return {
        //url: string,
        status: statusCode ?? 400,
        statusText: statusText ?? 'ERROR',
        body: {
            success: false,
            error: {
                message: [{
                    type: 'GLOBAL',
                    statusCode: statusCode ?? 400,
                    field: '',
                    message: errorMessage
                }]
            }
        }
    }
}
